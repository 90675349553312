import Component from "./component";
import assets from "./assets";
import {CalculatingContext} from "./main";

export default class Person extends Component {

    private level: number;
    private highlight: boolean = false;

    constructor(x: number, y: number, w: number, h: number, l: number) {
        super();
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
        this.level = l;
    }

    public calc(context: CalculatingContext): void {
        this.highlight = this.level == context.level;
    }

    public draw(context: CanvasRenderingContext2D): void {
        context.transform(1, 0, 0, 0.5, 0, 0);

        if (this.highlight) {
            context.fillStyle = "rgba(2, 148, 255, 0.125)";
            context.beginPath();
            context.arc(this.x, (this.y - (this.width / 4)) * 2, (this.width / 2) * 1.5, 0, Math.PI * 2);
            context.fill();
            context.fillStyle = "rgba(2, 148, 255, 0.25)";
        } else {
            context.fillStyle = "rgba(0,0,0,0.05)";
        }
        context.beginPath();
        context.arc(this.x, (this.y - (this.width / 4)) * 2, (this.width / 2) * 1.25, 0, Math.PI * 2);
        context.fill();

        context.transform(1, 0, 0, 2, 0, 0);

        context.drawImage(assets.image("person-1"), this.x - (this.width / 2), this.y - this.height, this.width, this.height);
    }
}