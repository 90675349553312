import * as locales from "./locale";

const state = {
    directSales: 0,
    directAffiliates: 0,
    indirectSales: 0,
    indirectAffiliates: 0,
    level: -1,
};

const change = (field: string) => (e: any) => {
    let sanitized = String(e.target.value).replace(/\D/, "");
    state[field] = Number(sanitized) || 0;
    e.target.value = state[field];
};

const add = (field: string, value: number) => () => {
    state[field] += value;

    if (state[field] <= 0) {
        state[field] = 0;
    }
    let e = document.querySelector<HTMLInputElement>(`#${field}Count`);
    e.value = state[field];
};

let fields = [
    "directSales",
    "directAffiliates",
    "indirectSales",
    "indirectAffiliates",
];

fields.forEach(f => {
    let eCount = document.querySelector<HTMLInputElement>(`#${f}Count`);
    eCount.setAttribute("value", state[f]);
    eCount.addEventListener("change", change(f));
    eCount.addEventListener("keyup", change(f));
    eCount.addEventListener("keydown", change(f));

    document.getElementById(`${f}Add`).addEventListener("click", add(f, 1));
    document.getElementById(`${f}Sub`).addEventListener("click", add(f, -1));
});

export default state;

document.addEventListener("mousemove", e => {
    let el = document.querySelector(".result .detail .item:hover");
    if (el) {
        state.level = Number(el.getAttribute("key"));
    } else {
        state.level = -1;
    }
});


if (location.search.substring(1) == "configure") {
    try {
        let commissions = prompt("Commissions separated by ;").split(";").map(c => Number(c));
        let locale = prompt(`Language (${locales.available().join(", ")})`).toLowerCase();
        let currency = prompt("Currency");
        let title = prompt("Title");

        let settings = {commissions, locale, currency, title};

        let encoded = btoa(escape(JSON.stringify(settings)));

        open(`${location.origin}?${encoded}`, "_self");
    } catch (error) {
        alert("An error has occurred, please try again");
        console.error("configure", error);
    }
}