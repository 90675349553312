import Component from "./component";
import {CalculatingContext} from "./main";

export default class Disk extends Component {
    constructor(x: number, y: number, w: number, h: number) {
        super();
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
    }

    public calc(context: CalculatingContext): void {
        // not implemented
    }

    public draw(context: CanvasRenderingContext2D): void {
        context.fillStyle = "#f0f0f0";
        context.beginPath();
        context.moveTo(this.x - (this.width / 2), this.y);
        context.lineTo(this.x - (this.width / 2), this.y + this.height);
        context.lineTo(this.x + (this.width / 2), this.y + this.height);
        context.lineTo(this.x + (this.width / 2), this.y);
        context.fill();

        context.transform(1, 0, 0, 0.5, 0, 0);

        context.fillStyle = "#f0f0f0";
        context.beginPath();
        context.arc(this.x, this.y * 2 + this.height * 2, this.width / 2, 0, Math.PI * 2);
        context.fill();

        context.fillStyle = "#f5f5f5";
        context.beginPath();
        context.arc(this.x, this.y * 2, this.width / 2, 0, Math.PI * 2);
        context.fill();

        context.transform(1, 0, 0, 2, 0, 0);


    }
}