const locales = {
    us: {
        how_many_sales_can_you_make: "How many sales can you make in a year?",
        how_many_affiliates_can_you_bring: "How many associates can you bring in a year?",
        how_many_sales_can_each_affiliate_make: "How many sales can each associate make in a year?",
        how_many_affiliates_can_each_affiliate_bring: "How many associates can each associate bring in a year?",
        your_estimated_income: "Your estimated monthly recurring income after one year building your business",
    },
    br: {
        how_many_sales_can_you_make: "Quantas vendas você consegue fazer em um ano?",
        how_many_affiliates_can_you_bring: "Quantos associados você consegue trazer em um ano?",
        how_many_sales_can_each_affiliate_make: "Quantas vendas cada associado consegue fazer em um ano?",
        how_many_affiliates_can_each_affiliate_bring: "Quantos associados cada associado consegue trazer em um ano?",
        your_estimated_income: "Sua renda recorrente estimada após um ano construindo seu negócio",
    },
    br_builder: {
        how_many_sales_can_you_make: "Quantas vendas você consegue fazer em um ano?",
        how_many_affiliates_can_you_bring: "Quantos associados você consegue trazer em um ano?",
        how_many_sales_can_each_affiliate_make: "Quantas vendas cada associado consegue fazer em um ano?",
        how_many_affiliates_can_each_affiliate_bring: "Quantos associados cada associado consegue trazer em um ano?",
        your_estimated_income: "Seus ganhos estimados após um ano de divulgação do Plano Builder",
    },
    de: {
        how_many_sales_can_you_make: "Wie viele Verkäufe kannst Du in 1 Jahr erzielen?",
        how_many_affiliates_can_you_bring: "Wie viele werden davon innerhalb 1 Jahres Associate?",
        how_many_sales_can_each_affiliate_make: "Wie viele Verkäufe erzielt jeder Associate pro Jahr?",
        how_many_affiliates_can_each_affiliate_bring: "Wie viele Associates kann jeder Associate in 1 Jahr bringen?",
        your_estimated_income: "Dein voraussichtliches passives Einkommen nach ca. 1 Jahr aktiven Arbeitens an Deinem Business",
    },
    es: {
        how_many_sales_can_you_make: "¿Cuántas ventas puedes hacer en un año?",
        how_many_affiliates_can_you_bring: "¿Cuántos asociados puedes traer en un año?",
        how_many_sales_can_each_affiliate_make: "¿Cuántas ventas cada asociado puede hacer en un año?",
        how_many_affiliates_can_each_affiliate_bring: "¿Cuántos asociados cada asociado puede traer en un año?",
        your_estimated_income: "Su ingreso recurrente estimado después de un año de construir su negocio",
    },
    fr: {
        how_many_sales_can_you_make: "Combien de ventes pouvez-vous faire par an?",
        how_many_affiliates_can_you_bring: "Combien d'associés pouvez-vous apporter par an?",
        how_many_sales_can_each_affiliate_make: "Combien de ventes chaque associé peut-il réaliser dans une année?",
        how_many_affiliates_can_each_affiliate_bring: "Combien d'associés chaque associé peut- il rapporter au cours d'une année?",
        your_estimated_income: "Votre revenu récurrent estimé après un an de développement de votre entreprise",
    },
    it: {
        how_many_sales_can_you_make: "Quante vendite puoi fare in un anno?",
        how_many_affiliates_can_you_bring: "Quante associati puoi portare in un anno?",
        how_many_sales_can_each_affiliate_make: "Quante vendite può fare ogni associato in un anno?",
        how_many_affiliates_can_each_affiliate_bring: "Quante associati ogni associato può portare in un anno?",
        your_estimated_income: "Le entrate ricorrenti stimate dopo un anno di attività",
    },
};

export function available() {
    let list = [];
    for (const k in locales) {
        if (locales.hasOwnProperty(k)) {
            list.push(k);
        }
    }
    return list;
}

export default locales;