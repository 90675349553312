import form from "./form";
import People from "./people";
import Disk from "./disk";
import Component from "./component";
import locale from "./locale";

export interface CalculatingContext {
    directSales: number;
    directAffiliates: number;
    indirectSales: number;
    indirectAffiliates: number;
    affiliates: number[];
    commissions: number[];
    level: number;
}

class Main {
    private readonly context: CanvasRenderingContext2D;
    private readonly width: number;
    private readonly height: number;
    private components: Component[];
    private settings: {
        commissions: number[],
        lang: string,
        translations: {},
        currency: string
    };

    constructor() {
        this.settings = this.configure();
        this.translate();

        let canvas = this.getCanvas();
        this.context = canvas.getContext("2d");
        this.width = canvas.width;
        this.height = canvas.height;

        let margin = this.width * 0.3;
        let width = this.width * 0.6 * 0.9;

        if (this.width <= 1024) {
            margin = this.width / 2;
            width = this.width;
        }

        let x = this.width - margin;
        let y = this.height * 0.5;

        this.components = [
            new Disk(x, y, width, this.height / 10),
            new People(x, y, width, width),
        ];
    }

    configure() {
        let defaultSettings = {
            commissions: [1, 2, 3, 4],
            locale: "br",
            currency: "R$",
            title: "",
        };

        let cleaner = (a: string, b: string) => b.length > a.length ? b : a;
        let paramEncoded = window.location.search.substring(1)
            .split("/").reduce(cleaner, "")
            .split("#").reduce(cleaner, "")
            .split("&").reduce(cleaner, "");

        let param = decodeURIComponent(paramEncoded);
        let paramSettings = param ? JSON.parse(unescape(atob(param))) : {};

        let settings = {...defaultSettings, ...paramSettings};

        if (settings.commissions.length == 2) {
            document.getElementById("indirectAffiliates").remove();
        }

        document.getElementById("plan-title").textContent = settings.title;

        settings.translations = locale[settings.locale] || locale["us"];

        const params = window.location.search.substring(1).split("&");
        if (params.filter(s => s == "header=disable").length > 0) {
            document.body.classList.add("header-disable");
        }

        if (params.filter(s => s == "canvas=disable").length > 0) {
            document.body.classList.add("canvas-disable");
        }

        return settings;
    }

    getCanvas() {
        let canvas = document.createElement("canvas");
        if (window.innerWidth <= 1024) {
            const container = document.querySelector(".form");
            const padding = window.getComputedStyle(container, null).getPropertyValue("padding-left");
            const size = container.clientWidth - Number(padding.replace("px", "")) * 2
            canvas.width = size;
            canvas.height = size;
            document.body.appendChild(canvas);
        } else {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            document.body.prepend(canvas);
        }
        return canvas;
    }

    getAffiliates() {
        let affiliates: number[] = [1];
        let current = form.directAffiliates;
        for (let i = 0; i < this.settings.commissions.length - 1; i++) {
            affiliates.push(current);
            current = current * form.indirectAffiliates;
        }
        return affiliates;
    }

    draw() {
        let context = this.getContext();
        this.components.forEach(c => c.calc(context));

        this.context.clearRect(0, 0, this.width, this.height);
        this.components.forEach(c => c.draw(this.context));

        this.result(context);

        requestAnimationFrame(this.draw.bind(this));
    }

    getContext(): CalculatingContext {
        return {
            directSales: form.directSales,
            directAffiliates: form.directAffiliates,
            indirectSales: form.indirectSales,
            indirectAffiliates: form.indirectAffiliates,
            affiliates: this.getAffiliates(),
            commissions: this.settings.commissions,
            level: form.level,
        };
    }

    translate() {
        document.querySelectorAll(".translate").forEach(e => {
            e.textContent = this.settings.translations[e.textContent] || e.textContent;
        });
    }

    result(context: CalculatingContext) {
        let total = 0;
        let detail: string[] = [];

        for (let i = 0; i < context.affiliates.length; i++) {
            let affiliatesCount = context.affiliates[i];
            let salesCount = i == 0 ? context.directSales : context.indirectSales;
            let commissionValue = context.commissions[i];
            let value = Number(affiliatesCount * salesCount * commissionValue);

            detail.push(`
        <div class="item ${i == context.level ? "highlight" : ""}" key="${i}">
          ${affiliatesCount} x ${salesCount} x ${this.settings.currency} ${this.number(commissionValue, 2)} = ${this.settings.currency} ${this.number(value, 2)}
        </div>
      `);

            total += value;
        }

        let detailElement = document.querySelector(".result .detail");
        detailElement.innerHTML = detail.join("");

        let totalElement = document.querySelector(".result .total");
        totalElement.textContent = `${this.settings.currency} ${this.number(total, 2)}`;
    }

    number(n: number, decimal = 0): string {
        let s = n.toFixed(decimal);

        let f = s;
        for (let i = 0; i < (s.length / 3) - decimal; i++) {
            f = f.replace(/([0-9]{3})([. |,])/, ",$1$2");
        }

        return f;
    }
}

let main = new Main();
main.draw();