import Component from "./component";
import Person from "./person";
import * as R from "ramda";
import {CalculatingContext} from "./main";

export default class People extends Component {
    private person: Person[];
    private offset: number;

    constructor(x: number, y: number, w: number, h: number) {
        super();
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
        this.offset = Math.PI * 2 * Math.random();
    }

    public calc(context: CalculatingContext): void {
        this.person = [];

        let size = this.width / 10;

        for (let i = 0; i < context.affiliates.length; i++) {
            let space = ((this.height / 2) * 0.9 / (context.affiliates.length - 1)) * i;
            let count = context.affiliates[i];
            let limit = 10 * (i + 1);

            if (context.affiliates.length <= 4) {
                limit *= 2;
            }

            if (context.affiliates.length <= 2) {
                limit *= 2;
            }

            if (count > limit) {
                count = limit;
            }

            for (let j = 0; j < count; j++) {
                let r = ((Math.PI * 2) / count) * j + this.offset * i;
                let x = this.x + Math.cos(r) * space;
                let y = this.y + ((Math.sin(r) * space) / 2);

                let u = 148 / 543;

                let w = size * u;
                let h = size;

                this.person.push(new Person(x, y, w, h, i));
            }
        }

        R.forEach(p => {
            p.calc(context);
        }, R.sortBy(p => p.y, this.person));
    }

    public draw(context: CanvasRenderingContext2D): void {
        R.forEach(p => {
            p.draw(context);
        }, R.sortBy(p => p.y, this.person));
    }
}