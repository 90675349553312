import {CalculatingContext} from "./main";

export default abstract class Component {
    public x: number = 0;
    public y: number = 0;
    public width: number = 0;
    public height: number = 0;

    public abstract calc(context: CalculatingContext): void

    public abstract draw(context: CanvasRenderingContext2D): void
}